<template>
  <v-app id="inspire">
    <div class="app-context">
      <v-app-bar
        absolute
        color="#fcb69f"
        dark
        shrink-on-scroll
        src="https://picsum.photos/1920/1080?random"
      >
        <template v-slot:img="{ props }">
          <v-img
            v-bind="props"
            gradient="to top right, rgba(19,84,122,.5), rgba(128,208,199,.8)"
          ></v-img>
        </template>

        <div class="logo" style="margin: auto; width: 1000px">
          <v-row>
            <v-col cols="12" md="2"
              ><img class="header_logo" src="@/assets/images/logo_default.svg"
            /></v-col>
            <v-col cols="12" md="8"
              ><h1
                style="
                  font-size: 32px;
                  position: relative;
                  top: 3px;
                  margin-left: 20px;
                "
              >
                상품 수정하기
              </h1></v-col
            >
          </v-row>
        </div>

        <v-spacer></v-spacer>
      </v-app-bar>
      <div style="margin: 150px 20px 0 20px">
        <div style="position: relative; width: 80%; left: 8%">
          <div
            style="
              width: 100%;
              text-align: center;
              font-size: 32px;
              margin: 10px;
            "
          >
            <span>상품 수정하기</span>
          </div>
          <v-container>
            <div class="row">
              <div>
                <v-text-field
                  class="prodCd"
                  label="상품 코드를 입력하세요(5자 이내)"
                  :rules="rules"
                  hide-details="auto"
                  style="width: 700px"
                  @change="prodCdSave()"
                  :value="this.prodParam.prodCd"
                ></v-text-field>
              </div>
            </div>
            <div class="row">
              <div>
                <v-text-field
                  class="prodNm"
                  label="상품명을 입력하세요(50자 이내)"
                  :rules="rules"
                  hide-details="auto"
                  style="width: 700px; margin: 20px 0 50px 0"
                  @change="prodNmSave()"
                  :value="this.prodParam.prodNm"
                ></v-text-field>
              </div>
            </div>
            <div class="row">
              <div>
                <v-textarea
                  class="prodDtl"
                  outlined
                  name="input-7-4"
                  label="상품설명을 입력하세요(200자 이내)"
                  height="200"
                  style="width: 700px"
                  @change="prodDescSave()"
                  :value="this.prodParam.prodDesc"
                ></v-textarea>
              </div>
            </div>
            <div class="row">
              <div>
                <v-text-field
                  class="prodUnitNm"
                  label="단위명을 입력하세요(20자 이내)"
                  :rules="rules"
                  hide-details="auto"
                  style="width: 700px"
                  @change="unitNmSave()"
                  :value="this.prodParam.unitNm"
                ></v-text-field>
              </div>
            </div>
            <div class="row">
              <div>
                <v-text-field
                  class="prodPrice"
                  label="금액을 입력하세요"
                  :rules="rules"
                  hide-details="auto"
                  style="width: 700px; margin: 20px 0 30px 0"
                  @change="priceSave()"
                  :value="this.prodParam.price"
                ></v-text-field>
              </div>
            </div>
            <div class="row">
              <div style="font-weight: bold; font-size: 18px">적용 기간</div>
            </div>
            <span style="width: 50%; display: flex; margin: 20px 0 30px -12px">
              <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <input
                    ref="dateInputFirst"
                    type="text"
                    style="
                      width: 40%;
                      text-align: center;
                      border: 1px solid #000;
                    "
                    v-model="conditional_date_array[0]"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @click="pickerOffset(0)"
                  />
                </template>
                <v-date-picker
                  calss="picker1"
                  color="#146F83"
                  header-color="#146F83"
                  v-model="picker_first"
                  @change="click_picker(0)"
                  locale="kr"
                  no-title
                  @input="menu1 = false"
                >
                </v-date-picker>
              </v-menu>
              <span style="width: 20%; font-size: 30px; text-align: center"
                >~</span
              >
              <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <input
                    ref="dateInputSecond"
                    type="text"
                    style="
                      width: 40%;
                      text-align: center;
                      border: 1px solid #000;
                    "
                    v-model="conditional_date_array[1]"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @click="pickerOffset(1)"
                  />
                </template>
                <v-date-picker
                  calss="picker1"
                  color="#146F83"
                  header-color="#146F83"
                  v-model="picker_second"
                  @change="click_picker(1)"
                  locale="kr"
                  no-title
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </span>
            <div class="row" style="margin-top: 35px">
              <input
                type="file"
                name="profile_pt"
                id="profile_pt"
                @change="previewImage(this, 'View_area')"
              />
              <div
                id="View_area"
                style="
                  position: relative;
                  margin-top: 20px;
                  max-width: 800px;
                  max-height: 1000px;
                  color: black;
                  border: 0px solid black;
                  dispaly: inline;
                  clear: both;
                "
              >
                <img
                  id="prev_View_area"
                  class="obj"
                  :src="
                    'https://www.drrefer.net/api/pic/' + this.prodParam.attchId
                  "
                  style="width: 100%; height: 100%"
                />
              </div>

              <div class="row add-btn" style="margin-top: 40px">
                <div
                  style="
                    width: 100%;
                    text-align: center;
                    font-size: 32px;
                    margin: 10px;
                  "
                  @click="modifyProd()"
                >
                  <v-btn
                    class="primary white--text"
                    outlined
                    tile
                    dense
                    large
                    style="margin: 0 0px 0 0px; width: 350px; height: 70px"
                    ><v-icon size="35" style="margin-right: 15px"
                      >mdi-update</v-icon
                    >
                    <span style="font-size: 35px; line-height: 10px"
                      >수정 하기</span
                    >
                  </v-btn>
                </div>
              </div>
            </div>
          </v-container>
          <div style="height: 100px" />
        </div>
      </div>
      <default-popup v-if="checkPopup" :popupSet="popupSet" />
    </div>
  </v-app>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
import ImageViewer from "@/components/ImageViewer";
import DefaultPopup from "@/components/modal/DefaultPopup";
export default {
  components: { ImageViewer, DefaultPopup },
  data() {
    return {
      conditional_date_array: [
        new Date()
          .toISOString()
          .substr(0, 10)
          .replace("T", " ")
          .replaceAll("-", ""),
        "99991231",
      ],
      picker_first: "",
      picker_first_offset: [0, 0],
      picker_second: "",
      picker_second_offset: [0, 0],
      menu1: false,
      menu2: false,
      rules: [
        (value) => !!value || "최소 2자 이상 입력해주세요.",
        (value) => (value && value.length >= 2) || "최소 2자 이상 입력해주세요",
      ],
      photo: "",
      prodParam: {
        prodCd: "",
        prodNm: "",
        prodDesc: "",
        unitNm: "",
        price: "",
        aplyStrDt: new Date()
          .toISOString()
          .substr(0, 10)
          .replace("T", " ")
          .replaceAll("-", ""),
        aplyEndDt: "99991231",
        attchId: "",
      },
      attachment: {
        filename: null,
        url: null,
        source: null,
      },
      popupSet: {},
      foducs: {},
      showImage: false,
      mapLocation: {
        posX: 37.578801,
        posY: 126.966441,
      },
      pr: {
        logoAttchId: null,
        hospNm: null,
        hospIntro: null,
        conConts: null,
        drPicAttchId: null,
        drNm: null,
        drHist: null,
        doctIntro: null,
        addrDtl: null,
        hospTel: null,
        hospFaxNo: null,
        etcConts: null,
        mapAttchId: null,
        picAttchId: null,
        prAttchId: null,
        rsvUrl: null,
        homepage: null,
        drId: null,
        docAddvTitle: null,
        docAddvConts: null,
        etcPage: [
          {
            type: null,
            value: null,
          },
          {
            type: null,
            value: null,
          },
          {
            type: null,
            value: null,
          },
        ],
      },
    };
  },
  created() {
    // this.getBasicData();
    // this.getPromotionData();
    this.getProd();
    const sendAppheaderItem = {
      method: "appHeaderOff",
      item: "off",
    };
    this.$emit("send-app-header-method", sendAppheaderItem);
  },
  mounted() {
    const sendAppheaderItem = {
      method: "appHeaderOff",
      item: "off",
    };
    this.$emit("send-app-header-method", sendAppheaderItem);
    //console.log("===============================================");
    //console.log($("#prev_View_area"));
  },
  computed: {
    ...mapState("basic", ["checkPopup"]),
    ...mapGetters("join", ["GET_BASIC"]),
    ...mapGetters("promotion", ["GET_PROMOTION"]),
    ...mapGetters("admin", ["GET_PROD_DATA"]),
  },
  methods: {
    ...mapMutations("basic", ["SET_POPUP", "SET_CHILD_LOADER"]),
    ...mapActions("join", ["FETCH_BASIC_TARGET_UN_AUTH"]),
    ...mapActions("promotion", ["FETCH_PROMOTION_TARGET_UN_AUTH"]),
    ...mapActions("admin", ["BRING_PROD_DATA"]),
    ...mapActions("prod", ["MODIFY_PROD"]),
    viewPhoto(src) {
      this.photo = src;
      //console.log(this.photo);
      this.showImage = true;
    },
    // async getBasicData() {
    //   try {
    //     await this.FETCH_BASIC_TARGET_UN_AUTH(this.$route.params.code).then(
    //       () => {
    //         //console.log("찾기 회원정보", this.GET_BASIC);
    //         if (this.GET_BASIC.hospNm !== null) {
    //           this.pr.hospNm = this.GET_BASIC.hospNm;
    //         }
    //         if (this.GET_BASIC.drNm !== null) {
    //           this.pr.drNm = this.GET_BASIC.drNm;
    //         }
    //         if (this.GET_BASIC.drId !== null) {
    //           this.pr.drId = this.GET_BASIC.drId;
    //         }
    //         if (this.GET_BASIC.addr !== null) {
    //           this.pr.addr = this.GET_BASIC.addr;
    //         }
    //         if (this.GET_BASIC.addrDtl !== null) {
    //           this.pr.addrDtl = this.GET_BASIC.addrDtl;
    //         }
    //         if (this.GET_BASIC.posX !== null) {
    //           this.mapLocation.posX = this.GET_BASIC.posX;
    //         }
    //         if (this.GET_BASIC.posY !== null) {
    //           this.mapLocation.posY = this.GET_BASIC.posY;
    //         }
    //       }
    //     );
    //   } catch (error) {
    //     console.log("error:", error);
    //   }
    // },
    // async getPromotionData() {
    //   /**
    //    *  삭제,변경 => 1
    //    *  유지,입력 => 0
    //    *
    //    */
    //   try {
    //     await this.FETCH_PROMOTION_TARGET_UN_AUTH(this.$route.params.code).then(
    //       () => {
    //         //console.log("찾기 조회정보", this.GET_PROMOTION);
    //         if (this.GET_PROMOTION.logoAttchId !== null) {
    //           this.pr.logoAttchId =
    //             "https://www.drrefer.net/api/pic/" +
    //             this.GET_PROMOTION.logoAttchId;
    //         }
    //         if (this.GET_PROMOTION.hospIntro !== null) {
    //           this.pr.hospIntro = this.GET_PROMOTION.hospIntro;
    //         }
    //         if (this.GET_PROMOTION.conConts !== null) {
    //           this.pr.conConts = this.GET_PROMOTION.conConts;
    //         }
    //         if (this.GET_PROMOTION.drPicAttchId !== null) {
    //           this.pr.drPicAttchId =
    //             "https://www.drrefer.net/api/pic/" +
    //             this.GET_PROMOTION.drPicAttchId;
    //         }
    //         if (this.GET_PROMOTION.drHist !== null) {
    //           this.pr.drHist = this.GET_PROMOTION.drHist;
    //         }
    //         if (this.GET_PROMOTION.doctIntro !== null) {
    //           this.pr.doctIntro = this.GET_PROMOTION.doctIntro;
    //         }
    //         if (this.GET_PROMOTION.hospTel !== null) {
    //           this.pr.hospTel = this.phoneFomatter(this.GET_PROMOTION.hospTel);
    //         }
    //         if (this.GET_PROMOTION.hospFaxNo !== null) {
    //           this.pr.hospFaxNo = this.phoneFomatter(
    //             this.GET_PROMOTION.hospFaxNo
    //           );
    //         }
    //         if (this.GET_PROMOTION.etcConts !== null) {
    //           this.pr.etcConts = this.GET_PROMOTION.etcConts;
    //         }
    //         if (this.GET_PROMOTION.mapAttchId !== null) {
    //           this.pr.mapAttchId =
    //             "https://www.drrefer.net/api/pic/" +
    //             this.GET_PROMOTION.mapAttchId;
    //         }
    //         if (this.GET_PROMOTION.picAttchId !== null) {
    //           this.pr.picAttchId =
    //             "https://www.drrefer.net/api/pic/" +
    //             this.GET_PROMOTION.picAttchId;
    //         }
    //         if (this.GET_PROMOTION.prAttchId !== null) {
    //           this.pr.prAttchId =
    //             "https://www.drrefer.net/api/pic/" +
    //             this.GET_PROMOTION.prAttchId;
    //         }
    //         if (this.GET_PROMOTION.rsvUrl !== null) {
    //           this.pr.rsvUrl = this.GET_PROMOTION.rsvUrl;
    //         }
    //         if (this.GET_PROMOTION.homepage !== null) {
    //           this.pr.homepage = this.GET_PROMOTION.homepage;
    //         }
    //         if (
    //           this.GET_PROMOTION.url1 !== null &&
    //           this.GET_PROMOTION.url1 !== ""
    //         ) {
    //           this.pr.etcPage[0].value = this.GET_PROMOTION.url1;
    //         }
    //         if (
    //           this.GET_PROMOTION.urlNm1 !== null &&
    //           this.GET_PROMOTION.urlNm1 !== "" &&
    //           this.GET_PROMOTION.urlNm1 !== "선택안함"
    //         ) {
    //           this.pr.etcPage[0].type = this.GET_PROMOTION.urlNm1;
    //         }
    //         if (
    //           this.GET_PROMOTION.url2 !== null &&
    //           this.GET_PROMOTION.url2 !== ""
    //         ) {
    //           this.pr.etcPage[1].value = this.GET_PROMOTION.url2;
    //         }
    //         if (
    //           this.GET_PROMOTION.urlNm2 !== null &&
    //           this.GET_PROMOTION.urlNm2 !== "" &&
    //           this.GET_PROMOTION.urlNm2 !== "선택안함"
    //         ) {
    //           this.pr.etcPage[1].type = this.GET_PROMOTION.urlNm2;
    //         }
    //         if (
    //           this.GET_PROMOTION.url3 !== null &&
    //           this.GET_PROMOTION.url3 !== ""
    //         ) {
    //           this.pr.etcPage[2].value = this.GET_PROMOTION.url3;
    //         }
    //         if (
    //           this.GET_PROMOTION.urlNm3 !== null &&
    //           this.GET_PROMOTION.urlNm3 !== "" &&
    //           this.GET_PROMOTION.urlNm3 !== "선택안함"
    //         ) {
    //           this.pr.etcPage[2].type = this.GET_PROMOTION.urlNm3;
    //         }
    //         if (
    //           this.GET_PROMOTION.docAddvTitle !== null &&
    //           this.GET_PROMOTION.docAddvTitle !== ""
    //         ) {
    //           this.pr.docAddvTitle = this.GET_PROMOTION.docAddvTitle;
    //         }
    //         if (
    //           this.GET_PROMOTION.docAddvConts !== null &&
    //           this.GET_PROMOTION.docAddvConts !== ""
    //         ) {
    //           this.pr.docAddvConts = this.GET_PROMOTION.docAddvConts;
    //         }
    //       }
    //     );
    //   } catch (error) {
    //     console.log("error:", error);
    //   }
    // },
    async getProd() {
      //console.log("this.$route.params.code : ",this.$route.params.code);

      if (
        this.$route.params.code === null ||
        this.$route.params.code === undefined
      )
        this.$route.params.code = "";

      var prodNoBoo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|]{8,14}$/;

      if (!prodNoBoo.test(this.$route.params.code)) {
        this.SET_POPUP(true);
        this.popupSet.title = "존재하지 않는 상품번호";
        this.popupSet.content = "존재하지 않는 상품번호입니다.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
        return;
      }

      await this.BRING_PROD_DATA(this.$route.params.code).then(() => {
        this.prodParam = this.GET_PROD_DATA;
        this.conditional_date_array = [
          this.prodParam.aplyStrDt,
          this.prodParam.aplyEndDt,
        ];
      });
      //console.log("prod",this.prodParam);
    },
    prodCdSave() {
      this.prodParam.prodCd = event.target.value;
      //console.log("현재 prodParam : ",this.prodParam.prodCd);
    },
    prodNmSave() {
      this.prodParam.prodNm = event.target.value;
      //console.log("현재 prodNm : ",this.prodParam.prodNm);
    },
    prodDescSave() {
      this.prodParam.prodDesc = event.target.value;
      //console.log("현재 prodDesc : ",this.prodParam.prodDesc);
    },
    unitNmSave() {
      this.prodParam.unitNm = event.target.value;
      //console.log("현재 unitNm : ",this.prodParam.unitNm);
    },
    priceSave() {
      this.prodParam.price = event.target.value;
      //console.log("현재 price : ",this.prodParam.price);
    },
    previewImage(targetObj, View_area) {
      //console.log(targetObj);
      var profilePt = $("#profile_pt");
      //console.log("확인하기",profilePt);

      var preview = document.getElementById(View_area); //div id
      var ua = window.navigator.userAgent;

      //ie일때(IE8 이하에서만 작동)
      if (ua.indexOf("MSIE") > -1) {
        profilePt.select();
        try {
          var src = document.selection.createRange().text; // get file full path(IE9, IE10에서 사용 불가)
          var ie_preview_error = document.getElementById(
            "ie_preview_error_" + View_area
          );

          if (ie_preview_error) {
            preview.removeChild(ie_preview_error); //error가 있으면 delete
          }
          var img = document.getElementById(View_area); //이미지가 뿌려질 곳
          //이미지 로딩, sizingMethod는 div에 맞춰서 사이즈를 자동조절 하는 역할
          img.style.filter =
            "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='" +
            src +
            "', sizingMethod='scale')";
        } catch (e) {
          if (!document.getElementById("ie_preview_error_" + View_area)) {
            var info = document.createElement("<p>");
            info.id = "ie_preview_error_" + View_area;
            info.innerHTML = e.name;
            preview.insertBefore(info, null);
          }
        }
        //ie가 아닐때(크롬, 사파리, FF)
      } else {
        var files = profilePt[0].files;
        for (var i = 0; i < files.length; i++) {
          var file = files[i];
          var imageType = /image.*/; //이미지 파일일경우만.. 뿌려준다.
          if (!file.type.match(imageType)) continue;
          var prevImg = document.getElementById("prev_" + View_area); //이전에 미리보기가 있다면 삭제
          //console.log("prevImg :==========       :" ,prevImg);
          if (prevImg) {
            preview.removeChild(prevImg);
          }
          var img = document.createElement("img");
          img.id = "prev_" + View_area;
          img.classList.add("obj");
          img.file = file;
          img.style.maxWidth = "700px";
          img.style.maxHeight = "1000px";
          preview.appendChild(img);
          if (window.FileReader) {
            // FireFox, Chrome, Opera 확인.
            var reader = new FileReader();
            reader.onloadend = (function (aImg) {
              return function (e) {
                //console.log("결과",e.srcElement);
                aImg.src = e.srcElement.result;
              };
            })(img);
            reader.readAsDataURL(file);
          } else {
            // safari is not supported FileReader
            //alert('not supported FileReader');
            if (!document.getElementById("sfr_preview_error_" + View_area)) {
              var info = document.createElement("p");
              info.id = "sfr_preview_error_" + View_area;
              info.innerHTML = "not supported FileReader";
              preview.insertBefore(info, null);
            }
          }
        }

        this.attachment.source = files[0];
        //console.log("소스 확인",this.attachment.source);
      }
    },
    pickerOffset(picker) {
      if (picker === 0) {
        //console.log(this.$refs.dateInputFirst);
        const clientRect = this.$refs.dateInputFirst.getBoundingClientRect();
        //console.log(clientRect.top);
        //console.log(clientRect.left);

        this.picker_first_offset = [clientRect.left, clientRect.top];

        //console.log(this.$refs.menu1.styles);
      } else if (picker === 1) {
        //console.log(this.$refs.dateInputSecond);
        const clientRect = this.$refs.dateInputSecond.getBoundingClientRect();
        //console.log(clientRect.top);
        //console.log(clientRect.left);
        this.picker_second_offset = [clientRect.left, clientRect.top];
      }
    },
    async clickSearch() {
      //console.log(this.conditional_date_array);
      if (this.type === "전체") {
        this.conditional_condition = 0;
      } else if (this.type === "회신 완료") {
        this.conditional_condition = 1;
      } else if (this.type === "내원 완료") {
        this.conditional_condition = 2;
      } else if (this.type === "되의뢰 완료") {
        this.conditional_condition = 3;
      }

      //console.log(this.conditional_condition);
      /*
      console.log(
        this.$moment(this.conditional_date_array[1]).isAfter(
          this.conditional_date_array[0]
        )
      );
      */
      if (
        this.conditional_date_array[0] === "전체" &&
        this.conditional_date_array[1] === "전체"
      ) {
        this.conditional_date_array[0] = null;
        this.conditional_date_array[1] = null;
        this.reload();
      } else if (
        this.conditional_date_array[0] !== undefined &&
        this.conditional_date_array[0] !== null &&
        this.conditional_date_array[1] !== undefined &&
        this.conditional_date_array[1] !== null
      ) {
        if (
          this.$moment(this.conditional_date_array[1]).isAfter(
            this.conditional_date_array[0]
          )
        ) {
          this.reload();
        } else {
          this.SET_POPUP(true);
          this.popupSet.title = "서버요청 실패";
          this.popupSet.content =
            "검색조건이 알맞지 않습니다. 다시한번 확인해 주세요";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
        }
      } else {
        this.SET_POPUP(true);
        this.popupSet.title = "서버요청 실패";
        this.popupSet.content =
          "검색조건이 알맞지 않습니다. 다시한번 확인해 주세요";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
      }
    },
    click_picker(str) {
      if (str === 0) {
        //console.log(this.picker_first);
        const [year, month, day] = this.picker_first.split("-");
        //console.log(`${year}${month}${day}`);
        this.conditional_date_array[0] = `${year}${month}${day}`;
        this.prodParam.aplyStrDt = `${year}${month}${day}`;
        //console.log("현재 aplyStrDt : ", this.prodParam.aplyStrDt);
        //console.log("현재 aplyStrDt : ", this.prodParam.aplyStrDt);
      } else if (str === 1) {
        //console.log(this.picker_second);
        const [year, month, day] = this.picker_second.split("-");
        //console.log(`${year}${month}${day}`);
        this.conditional_date_array[1] = `${year}${month}${day}`;
        this.prodParam.aplyEndDt = `${year}${month}${day}`;
        //console.log("현재 aplyEndDt : ", this.prodParam.aplyEndDt);
      }
    },
    async conditional_date(type) {
      if (type === "all") {
        this.conditional_date_array = ["전체", "전체"];
      } else if (type === "month") {
        this.conditional_date_array = [
          this.$moment().subtract(0, "M").format("YYYYMM") + "01",
          this.$moment().format("YYYYMMDD"),
        ];
      } else if (type === "month_back") {
        this.conditional_date_array = [
          this.$moment().subtract(1, "M").format("YYYYMMDD"),
          this.$moment().format("YYYYMMDD"),
        ];
      } else if (type === "3month_back") {
        this.conditional_date_array = [
          this.$moment().subtract(3, "M").format("YYYYMMDD"),
          this.$moment().format("YYYYMMDD"),
        ];
      }
    },
    async modifyProd() {
      //유효성 검사

      //1. 상품코드
      if (this.prodParam.prodCd === null || this.prodParam.prodCd === undefined)
        this.prodParam.prodCd = "";

      var prodCdBoo = /^[0-9]{1,5}$/;

      if (!prodCdBoo.test(this.prodParam.prodCd)) {
        this.SET_POPUP(true);
        this.popupSet.title = "상품 코드 확인";
        this.popupSet.content = "상품코드는 숫자 1~5자 이내로 입력해주세요.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
        return;
      }

      //2. 상품명
      if (this.prodParam.prodNm === null || this.prodParam.prodNm === undefined)
        this.prodParam.prodNm = "";

      if (
        this.prodParam.prodNm.length < 2 ||
        this.prodParam.prodNm.length > 50
      ) {
        this.SET_POPUP(true);
        this.popupSet.title = "상품명 확인";
        this.popupSet.content = "상품명은 2~50자 이내로 입력해주세요.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
        return;
      }

      //3. 상품설명
      if (
        this.prodParam.prodDesc === null ||
        this.prodParam.prodDesc === undefined
      )
        this.prodParam.prodDesc = "";

      if (
        this.prodParam.prodDesc.length < 2 ||
        this.prodParam.prodDesc.length > 300
      ) {
        this.SET_POPUP(true);
        this.popupSet.title = "상품설명 확인";
        this.popupSet.content = "상품설명은 2~300자 이내로 입력해주세요.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
        return;
      }

      //4. 단위명
      if (this.prodParam.unitNm === null || this.prodParam.unitNm === undefined)
        this.prodParam.unitNm = "";

      if (
        this.prodParam.unitNm.length < 1 ||
        this.prodParam.unitNm.length > 20
      ) {
        this.SET_POPUP(true);
        this.popupSet.title = "단위명 확인";
        this.popupSet.content = "단위명은 1~20자 이내로 입력해주세요.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
        return;
      }

      //5. 금액
      if (
        this.prodParam.price.toString() === null ||
        this.prodParam.price.toString() === undefined
      )
        this.prodParam.price = "";

      var priceBoo = /^[0-9]{1,7}$/;

      if (!priceBoo.test(this.prodParam.price.toString())) {
        this.SET_POPUP(true);
        this.popupSet.title = "금액 확인";
        this.popupSet.content = "금액은 원단위 1~7자 이내로 입력해주세요.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
        return;
      }

      //6. 적용 시작일자
      if (
        this.prodParam.aplyStrDt === null ||
        this.prodParam.aplyStrDt === undefined
      )
        this.prodParam.aplyStrDt = "";

      var aplyStrDtBoo = /^[0-9]{8,8}$/;

      if (!aplyStrDtBoo.test(this.prodParam.aplyStrDt)) {
        this.SET_POPUP(true);
        this.popupSet.title = "적용 시작일자 확인";
        this.popupSet.content =
          "적용 시작일자가 정상적으로 작성되지 않았습니다. 일자를 확인해주세요.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
        return;
      }

      //7. 적용 종료일자
      if (
        this.prodParam.aplyEndDt === null ||
        this.prodParam.aplyEndDt === undefined
      )
        this.prodParam.aplyEndDt = "";

      var aplyEndDtBoo = /^[0-9]{8,8}$/;

      if (!aplyEndDtBoo.test(this.prodParam.aplyEndDt)) {
        this.SET_POPUP(true);
        this.popupSet.title = "적용 종료일자 확인";
        this.popupSet.content =
          "적용 종료일자가 정상적으로 작성되지 않았습니다. 일자를 확인해주세요.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
        return;
      }

      //8. 상품번호
      if (this.prodParam.prodNo === null || this.prodParam.prodNo === undefined)
        this.prodParam.prodNo = "";

      var prodNoBoo = /^[0-9]{8,14}$/;

      if (!prodNoBoo.test(this.prodParam.prodNo)) {
        this.SET_POPUP(true);
        this.popupSet.title = "상품번호 확인";
        this.popupSet.content = "상품번호 오류";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
        return;
      }

      if (
        this.prodParam.prodCd === "" ||
        this.prodParam.prodNm === "" ||
        this.prodParam.prodDesc === "" ||
        this.prodParam.unitNm === "" ||
        this.prodParam.price === "" ||
        this.prodParam.aplyStrDt === "" ||
        this.prodParam.aplyEndDt === ""
      ) {
        this.SET_POPUP(true);
        this.popupSet.title = "내용 확인";
        this.popupSet.content = "수정할 상품의 내용을 확인해주세요.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
      } else {
        try {
          const transedPrParam = JSON.stringify(this.prodParam);
          const transData = new FormData();
          transData.append("prodParam", transedPrParam);
          //console.log("파일 첨부 여부 : ", this.attachment.source);
          if (this.attachment.source !== null) {
            transData.append("attachFile", this.attachment.source);
          }
          await this.MODIFY_PROD(transData).then(() => {
            this.SET_POPUP(true);
            this.popupSet.title = "상품 수정 완료";
            this.popupSet.content = "상품을 성공적으로 수정하였습니다.";
            this.popupSet.popType = "custom";
            this.popupSet.confirmBtnText = "확인";
            this.popupSet.destination = true;
            this.popupSet.nextLink = "/v/adminMain/9";
          });
        } catch (error) {
          console.log("error:", error);
          if (error.data.status !== 5000 && error.data.status !== 5002) {
            this.SET_POPUP(true);
            this.popupSet.title = "상품 추가 실패";
            this.popupSet.content = "상품을 추가할 수 없습니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
          }
        }
      }
    },
  },
  destroyed() {
    const sendAppheaderItem = {
      method: "appHeaderOff",
      item: "on",
    };
    this.$emit("send-app-header-method", sendAppheaderItem);
  },
};
</script>

<style>
input,
textarea {
  -moz-user-select: auto;

  -webkit-user-select: auto;

  -ms-user-select: auto;

  user-select: auto;
}
.img-viewer {
  top: -200px !important;
}
.origin {
  position: relative !important;
  top: 340px !important;
  left: 90px !important;
}
.row {
  width: 950px;
}
.add-btn {
  position: relative;
  left: -80px;
}
</style>
